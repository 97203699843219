import { computed, markRaw, watch, type WatchStopHandle } from "vue";

import {
  type DsIAuthService,
  DsNotifier,
  useDsSystemNotifierStore,
} from "@devsalsa/vue-core";

import AuthUserChangedToast from "@/core/shared/helpers/Auth/AuthUserChangedToast.vue";
import AuthUserLoggedOutToast from "@/core/shared/helpers/Auth/AuthUserLoggedOutToast.vue";

import { container } from "tsyringe";

export class AuthTokenWatcher {
  protected static unwatch: WatchStopHandle;

  static start() {
    const authService = container.resolve<DsIAuthService>("AuthService");
    const token = computed(() => authService.getToken());
    AuthTokenWatcher.unwatch = watch(token, (newValue, oldValue) => {
      console.log(
        "AuthTokenWatcher",
        JSON.stringify(newValue),
        JSON.stringify(oldValue)
      );
      if (oldValue !== "" && newValue === "undefined") {
        if (document.visibilityState === "hidden") {
          //Only display the toast if the tab is not the active one.
          if (useDsSystemNotifierStore().userLoggedOutNotificationId === 0) {
            //Send notification
            const id = DsNotifier.sendInfo({
              duration: 0,
              enableClose: false,
              position: "top-0 right-0",
              overlay: true,
              component: markRaw({
                name: AuthUserLoggedOutToast,
              }),
            });
            useDsSystemNotifierStore().setUserLoggedOutId(id);
          }
        }
      } else if (oldValue !== "" && newValue !== "" && oldValue !== newValue) {
        //The user changed.
        if (useDsSystemNotifierStore().userChangedNotificationId === 0) {
          //Send notification
          const id = DsNotifier.sendInfo({
            duration: 0,
            enableClose: false,
            position: "top-0 right-0",
            overlay: true,
            component: markRaw({
              name: AuthUserChangedToast,
            }),
          });
          useDsSystemNotifierStore().setUserChangedId(id);
        }
      }
    });
  }

  static stop() {
    typeof AuthTokenWatcher.unwatch === "function" &&
      AuthTokenWatcher.unwatch();
  }
}
